import './select_checkbox'
import './required_checkbox'

document.addEventListener('DOMContentLoaded', () => {
    /* Menu control */
    const body = document.getElementsByTagName("BODY")[0]
    const toggleMenu = document.getElementById('toggleMenu')

    function menu(element, add) {
        toggleMenu.addEventListener('click', function() {
            element.classList.toggle(add)
        })
    }

    menu(body, 'show-body')

    for (const item of document.querySelectorAll('.page-navigation li a')) {
        const itemHref = item.getAttribute('href')
        if (typeof itemHref === 'string' && itemHref.includes('#link-hide')) {
            item.removeAttribute('href')
        }
    }


    // Because message 'Enter a valid email address.' is hidden.
    for (const email of document.querySelectorAll('.base-form input[type=email].has-error')) {
        if (email.value) {
            email.classList.add('email-error')
        }

        email.addEventListener('input', () => {
            email.classList.remove('email-error')
        })
    }
})
